import React from "react";
import PropTypes from "prop-types";

import HelpLabel from "../help-label";
import styles from "./input.module.scss";

const Input = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    error,
    label,
    placeholder,
    defaultValue,
    onChange,
    rows,
    help,
    min,
    max,
  } = props;

  let Tag = "input";

  if (type === "textarea") {
    Tag = type;
  }

  let shouldAutofocus = false;
  if (props.autofocus) {
    shouldAutofocus = true;
  }

  return (
    <React.Fragment>
      <HelpLabel label={label} name={name} help={help} />
      <Tag
        className={props.className || styles.input}
        name={name}
        id={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={ref}
        onChange={onChange}
        rows={rows} // So the textareas aren't too small to be useable for feedback
        autoFocus={shouldAutofocus}
        min={min}
        max={max}
      />
      <div className={styles.errorWrapper}>
        {error && (
          <span className={styles.error}>
            {error.message ? error.message : "This field is required"}
          </span>
        )}
      </div>
    </React.Fragment>
  );
});

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.shape({}),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  type: "text",
  placeholder: undefined,
  label: undefined,
  error: undefined,
  defaultValue: "",
  onChange: () => { },
  // rows: 10,
};

export default Input;
