import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { shortDate } from "../../../common/utils";
import { useUser } from "../../../queries/user";
import Navigation from "../../../components/navigation/navigation";
import ScrollArea from "../../../components/scroll-area/scroll-area";
import { useAssignmentList } from "../../../queries/student_assignments";
import { getTodos } from "../../../queries/todos";

import styles from "./dashboard.module.scss";

const Assignment = (props) => {
  const { assignment, index, isDemo } = props;
  const history = useHistory();

  const todos = useQuery(["todo-list"], getTodos, {
    cacheTime: 100,
    staleTime: 100,
    refetchOnMount: true,
    enabled: assignment.type !== "oral",
  });

  if (todos.isLoading) {
    return <div>Loading...</div>;
  }

  let stageText = "Submission";

  switch (assignment.state) {
    case "pending":
      stageText = "Stage: Pending";
      break;
    case "submission":
      stageText = "Stage: Submission";
      break;
    case "adv_review":
      stageText = "Stage: Review";
      break;
    case "review":
      stageText = "Stage: Review";
      break;
    case "review_feedback":
      stageText = "Stage: Score Reviews";
      break;
    case "adv_oral":
      stageText = "Stage 4: Oral Assignment";
      break;
    case "oral":
      stageText = "Stage 4: Oral Assignment";
      break;
    case "adv_review_feedback":
      stageText = "Stage: Score Reviews";
      break;
    case "adv_completed":
      stageText = "Completed";
      if (isDemo) {
        stageText = "";
      }
      break;
    case "completed":
      stageText = "Feedback Available";
      if (isDemo) {
        stageText = "";
      }
      break;
    case "active":
      stageText = "Stage: Active";
      break;
    default:
      stageText = "Stage: Unknown";
  }

  // if the user has a late submission or late review todos that are not yet
  // completed they need to NOT go to the completed page yet
  let hasLateIncompleteItems = false;

  todos.data &&
    todos.data.forEach((todo) => {
      if (todo.assignment_id === assignment.id) {
        if (
          todo.todo_action === "late_submission" ||
          todo.todo_action === "late_review"
        ) {
          if (!todo.is_complete) {
            hasLateIncompleteItems = true;
          }
        }
      }
    });

  let assignmentURL = `/my-assignments/${assignment.id}`;

  if (assignment.state == "completed" && !hasLateIncompleteItems) {
    assignmentURL = `/results/${assignment.id}`;
  }

  if (assignment.type === "oral") {
    assignmentURL = `/my-oral-assignments/${assignment.id}`;
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (assignment.state !== "pending") {
      history.push(assignmentURL);
    }
  };

  let dueText = (
    <div className="">
      <p className="block my-2 text-gray-800">Next items due</p>
      <p className="text-lg ">{shortDate(assignment.due_date)}</p>
    </div>
  );

  if (assignment.state == "pending") {
    dueText = (
      <div>
        <p>This assignment is not yet active</p>
      </div>
    );
  }

  if (assignment.state == "completed") {
    dueText = <div>&nbsp;</div>;
  }

  let title = assignment.title;

  if (isDemo && assignment.state == "completed") {
    title = "Students Find Feedback from Peers";
  }

  if (isDemo && assignment.state == "review_feedback") {
    title = `Optional ${title}`;
  }

  const boxClasses = classNames(
    `grid grid-cols-4 w-full rounded overflow-hidden shadow my-6 p-8 border`,
    {
      "cursor-pointer": assignment.state !== "pending",
      "bg-blue-200": assignment.state !== "completed",
      "hover:bg-blue-300":
        assignment.state !== "completed" && assignment.state !== "pending",
      "bg-gray-200": assignment.state === "completed",
    },
  );
  return (
    <div className={boxClasses} key={index} onClick={handleClick}>
      <div className="col-span-3">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>
        <span className="text-gray-600">{assignment.course_name}</span>
      </div>
      <div className="content-center text-center">
        <p className="mb-4 font-bold text-gray-700">{stageText}</p>
        {dueText}
      </div>
    </div>
  );
};

const DemoText = (props) => {
  return (
    <div className="container border border-blue-200 bg-blue-100 p-4 mt-4">
      <h2 className="text-2xl font-bold">
        Welcome to Peer Exchange's Demo Showing the Student Experience
      </h2>
      <p className="my-4">Peer Exchange is comprised of 2 or 3 stages.</p>

      <p className="my-4">
        <strong>Stage 1:</strong> Students submit an assignment. e.g., text,
        spreadsheet, slides, video.
      </p>

      <p className="my-4">
        <strong>Stage 2:</strong> Students review classmates' assignments and
        offer feedback.
      </p>

      <p className="my-4">
        <strong>Optional Stage 3:</strong> Students evaluate the quality of
        stage-2 feedback. Some instructors choose to omit this stage.
      </p>

      <p className="my-4">
        Summary of student work: Students see text feedback from peers &amp; a
        report that compares feedback among classmates.
      </p>

      <p className="my-4">
        When using Peer Exchange, student see only one stage at a time.
      </p>

      <p className="my-4">
        Student work remains anonymous unless you choose otherwise. Instructors
        have access to all work.
      </p>

      <p className="my-4">
        Click on "Your To-Do's" in each stage to walk through the demo.
      </p>

      <p className="my-4">
        Demo changes will be saved for 7 days, then deleted.
      </p>
    </div>
  );
};

const Dashboard = (props) => {
  const user = useUser();
  const assignments = useAssignmentList();

  // Handle demo modal instructions
  const [showDemoModal, setShowDemoModal] = useState(null);
  useEffect(() => {
    if (showDemoModal === null) {
      setShowDemoModal(user.data.is_demo);
    }
  }, [user]);

  if (user.isLoading || assignments.isLoading) {
    return (
      <div className={styles.container}>
        <Navigation title="Dashboard" />
        <ScrollArea>Loading...</ScrollArea>
      </div>
    );
  }

  if (user.isError || assignments.isError) {
    return (
      <div className={styles.container}>
        <Navigation title="Dashboard" />
        <ScrollArea>Error</ScrollArea>
      </div>
    );
  }

  // Determine welcome back text.  If they have any assignments that are beyond
  // the submission stage, say 'Welcome Back'
  let welcomeText = "Welcome";
  const advancedStageAssignments = assignments.data.filter(
    (a) => a.state !== "pending" && a.state !== "submission",
  );
  if (advancedStageAssignments.length > 0) {
    welcomeText = "Welcome Back";
  }

  return (
    <div className={styles.container}>
      <Navigation title="Dashboard" />
      <ScrollArea>
        {showDemoModal && <DemoText />}
        <div className={styles.content}>
          <div className={styles.welcome}>
            <h3>
              {welcomeText} {user.data.first_name}!
            </h3>
          </div>
          <div>
            {assignments.data.map((item, index) => (
              <Assignment
                assignment={item}
                index={index}
                key={index}
                isDemo={showDemoModal}
              />
            ))}
            {assignments.data.length === 0 && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-6"
                role="alert"
              >
                <span>You currently have no assignments assigned to you</span>
              </div>
            )}
          </div>
          {showDemoModal && (
            <div className="container border border-blue-200 bg-blue-100 p-4 my-6 text-center">
              Return to <em>Try Now</em> on HBP's content page For Instructor
              View
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

export default Dashboard;
