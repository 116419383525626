import React, { useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import classNames from "classnames";
import { getMe, userQuerySettings } from "../../../queries/user";
import {
	getAssignment,
	getAssignmentCompareResults,
} from "../../../queries/student_assignments";
import Button from "../../../components/button/button";
import Loading from "../../../components/loading/loading";
import Navigation from "../../../components/navigation/navigation";
import ScrollArea from "../../../components/scroll-area/scroll-area";
import styles from "./results.module.scss";
import ReactHtmlParser from "react-html-parser";

const buttonClasses = classNames(
	"rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer",
);

const ShowReview = (props) => {
	const { review, index } = props;

	let classes = classNames(
		"my-4 p-4 border border-gray-600 hover:cursor-pointer",
		{ "bg-gray-100": review.is_author },
		{ "bg-red-200": review.is_ai },
		{ "bg-blue-300": !review.is_author && !review.is_ai },
	);

	let prefixText = "Classmate's ";
	if (review.is_author) {
		prefixText = "Your ";
	} else if (review.is_ai) {
		prefixText = "AI ";
	}

	return (
		<div className={classes}>
			<h3 className="my-2 font-bold">
				#{index + 1} {prefixText}Review
			</h3>
			{review.qualitative.map((qualitative, i) => (
				<>
					<p className="my-2 font-bold">{qualitative.label}</p>
					<p className="my-2">{qualitative.answer}</p>
				</>
			))}
			{!review.qualitative.length && (
				<p className="my-2">
					No qualitative text questions in this assignment's review
				</p>
			)}
		</div>
	);
};

const DisplayComparativeReviews = (props) => {
	const { result, index, chosen, toggleChosen } = props;
	let isChosen = false;
	if (chosen == result.submission.id) {
		isChosen = true;
	}

	let classes = classNames(
		"my-4 p-4 border border-gray-400",
		{ "bg-gray-200": !isChosen },
		{ "bg-blue-200": isChosen },
		{ "hover:bg-gray-300": !isChosen },
	);

	let body = null;
	if (result.submission.body && result.submission.body != "<p></p>") {
		body = ReactHtmlParser(result.submission.body);
	} else if (result.submission.body_plaintext) {
		body = result.submission.body_plaintext;
	}

	return (
		<div className={classes} onClick={() => toggleChosen(result.submission.id)}>
			<h2 className="text-xl font-bold">Submission #{index + 1}</h2>
			{isChosen && (
				<div className="my-4">
					<div className="my-4 p-4 bg-blue-100">
						{body}
						{!body && (
							<p className="my-2 font-bold">
								If the Submission is an attachment or link, it's not visible in
								this report.
							</p>
						)}
					</div>
					{result.reviews.map((review, i) => (
						<ShowReview key={i} review={review} index={i} />
					))}
				</div>
			)}
		</div>
	);
};

const CompareResults = (props) => {
	const { id } = useParams();
	const history = useHistory();
	const [chosen, setChosen] = useState(null);
	const user = useQuery("me", getMe, userQuerySettings);
	const assignment = useQuery(["student-assignment", id], () =>
		getAssignment(id),
	);
	const results = useQuery(["student-assignment-results-compare", id], () =>
		getAssignmentCompareResults(id),
	);

	const toggleChosen = (newChoice) => {
		console.log(newChoice);
		if (newChoice === chosen) {
			setChosen(null);
		} else {
			setChosen(newChoice);
		}
	};

	if (user.isLoading || assignment.isLoading || results.isLoading) {
		return <Loading />;
	}

	let isMultiple = results.data.length > 1;

	return (
		<div className={styles.container}>
			<Navigation
				history={history}
				title="Compare Your Review to Others'"
				backURL={`/results/${id}`}
				hasBackButton
			/>
			<ScrollArea>
				<div className={styles.content}>
					<div className={styles.welcome}>
						<h3>Compare Your Reviews To Others'</h3>
						<span className={buttonClasses}>
							<Link to={`/results/${id}`}>Back to Results</Link>
						</span>
					</div>
					<p className="my-4">
						Click on {isMultiple ? "each" : "the"} Submission below to view the
						Submission and all Reviews.{" "}
						{isMultiple && (
							<span>Click it again to close and view another.</span>
						)}
					</p>
					<div className="my-6">
						{results.data.map((result, index) => (
							<DisplayComparativeReviews
								key={index}
								index={index}
								result={result}
								chosen={chosen}
								toggleChosen={toggleChosen}
							/>
						))}
					</div>
				</div>
			</ScrollArea>
		</div>
	);
};

export default CompareResults;
