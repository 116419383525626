import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useUser } from "../../../queries/user";
import {
	useEmbeddedOralAssignment,
	startEmbeddedOralAssignment,
} from "../../../queries/student_assignments";
import { useMutation } from "react-query";
import Navigation from "../../../components/navigation/navigation";
import Loading from "../../../components/loading/loading";

export const OralBegin = () => {
	const { id, questionID } = useParams();
	const history = useHistory();
	const user = useUser();
	const assignment = useEmbeddedOralAssignment(id);

	const mutation = useMutation("oral-submission-start", () => {
		startEmbeddedOralAssignment(id, questionID);
	});

	if (assignment.isLoading || user.isLoading) {
		return <Loading />;
	}

	const handleStartClick = (event) => {
		event.preventDefault();

		mutation.mutateAsync(
			{},
			{
				onSuccess: () => {
					console.log("oral submission started successfully");
					history.push(`/my-oral/${id}/${questionID}/started`);
					rollbar.info(
						`Started Question #${questionID} in oral assignment ${id}`,
					);
				},
				onError: (error, context) => {
					console.error("error starting oral submission", error);
					rollbar.error(error, context);
				},
			},
		);
	};

	return (
		<div>
			<Navigation
				history={history}
				title={assignment.data.title}
				backURL={`/my-oral/${id}`}
				hasBackButton
			/>
			<div className="min-h-screen bg-gray-200 p-4 flex items-center justify-center">
				<div>
					<div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
						<div className="pb-4 mb-4">
							<h2 className="text-2xl font-bold text-gray-800">
								Ready to Start?
							</h2>
						</div>
						<p className="text-gray-600 mb-6">
							You have 5 minutes to read the question and record your response.
						</p>
						<p className="text-gray-600 mb-6">
							When you are ready to talk <strong>you must</strong>
							select "Click Here to Start Recording".
						</p>
						<p className="text-gray-600 mb-6">
							Are you ready to start the timer?
						</p>
						<div className="flex justify-end space-x-4">
							<button
								type="button"
								onClick={handleStartClick}
								className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
							>
								Yes start my timer
							</button>
							<button
								type="button"
								onClick={() => history.goBack()}
								className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
							>
								No, go back
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OralBegin;
