import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getMe, userQuerySettings } from "../../../queries/user";
import { getTodos } from "../../../queries/todos";
import {
  getAssignment,
  getAssignmentSubmission as queryGetAssignmentSubmission,
  submitLateSubmission,
} from "../../../queries/student_assignments";
import AssignmentNavigation from "../../../components/assignment-navigation/assignment-navigation";
import styles from "./submission-create.module.scss";
import Loading from "../../../components/loading/loading";

function SubmissionLateSubmit() {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [previewOpen, setPreviewOpen] = useState(false); // This isn't really used
  const user = useQuery("me", getMe, userQuerySettings);
  const assignment = useQuery(["student-assignment", id], () =>
    getAssignment(id),
  );
  const sub = useQuery(["assignment-submission", id], () =>
    queryGetAssignmentSubmission(id),
  );

  // Handle completeness tracking
  const todos = useQuery(["todo-list"], getTodos, {
    cacheTime: 100,
    staleTime: 100,
    refetchOnMount: true,
  });

  const submit = useMutation(submitLateSubmission, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(["todo-list"]);
      history.push(`/my-assignments/${id}`);
    },
    onSuccess: (data, variables, context) => { },
    onError: (data, error, variables, context) => {
      rollbar.error(error, context);
    },
  });

  if (sub.isLoading || assignment.isLoading || user.isLoading) {
    return <Loading />;
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    submit.mutate(id);
  };

  return (
    <React.Fragment>
      <div
        className={classNames(styles.container, {
          "split-view": false,
        })}
      >
        <AssignmentNavigation
          title={assignment.data.title}
          setPreviewOpen={setPreviewOpen} // not really used
          previewOpen={previewOpen} // not really used
          isDemo={user.data.is_demo}
          showAssignment
        />
        <div className="mt-10 mx-auto">
          <h3 className="text-2xl w-full text-center">
            Finished with your Submission?
          </h3>
          <p className="my-6 w-full text-center">
            If not, click the <strong>X</strong> above to keep working on your
            Submission.
          </p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleSubmitForm}
          >
            Yes, I'm finished with my Submission and ready to return and review
            classmates' work for Stage 2.
          </button>
          <p className="my-4 w-full text-center text-sm">
            This operation cannot be undone.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SubmissionLateSubmit;
