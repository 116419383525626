import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
	getAssignmentParticipation,
	getAssignment,
	getAssignmentDefaults,
} from "../../../queries/admin-queries";
import Loading from "../../../components/loading/loading";
import Checkmark from "../../../components/checkmark/checkmark";
import X from "../../../components/x/x";

const AssignmentReportsParticipation = () => {
	const { id } = useParams();
	const assignment = useQuery(
		["admin-assignment", id],
		() => getAssignment(id),
		getAssignmentDefaults,
	);
	const participation = useQuery(
		["admin-assignment-participation", id],
		() => getAssignmentParticipation(id),
		{ cacheTime: 60000, staleTime: 10000, keepPreviousData: true },
	);

	if (participation.isLoading || assignment.isLoading) {
		return <Loading />;
	}

	return (
		<React.Fragment>
			<h1 className="text-2xl font-bold my-6">All Stage Participation</h1>
			<table>
				<thead>
					<tr>
						<th>Participant</th>
						<th>Stage 1: Submission</th>
						<th>Likely to Participate in Later Stages</th>
						{assignment.data.allow_late_work && <th>Stage 1: Late</th>}
						<th>Stage 2: Reviews</th>
						<th>Stage 3: Score Reviews</th>
					</tr>
				</thead>
				<tbody>
					{participation.data.map((p) => {
						let committed = null;
						if (p.committed == true) {
							committed = <Checkmark />;
						} else if (p.committed == false) {
							committed = <X />;
						} else {
							committed = "No Answer";
						}

						let lateArea = null;
						if (p.late_submission) {
							lateArea = <span className="text-red-500">LATE</span>;
						}

						return (
							<tr key={p.id}>
								<td>
									{p.first_name} {p.last_name}
								</td>
								<td>
									{p.submission ? (
										<span className="text-green-500">COMPLETE</span>
									) : (
										<span className="text-red-500">INCOMPLETE</span>
									)}
								</td>
								<td>{committed}</td>
								{assignment.data.allow_late_work && <td>{lateArea}</td>}
								<td>{p.review_participation.toUpperCase()}</td>
								<td>{p.feedback_participation.toUpperCase()}</td>
							</tr>
						);
					})}
					{participation.data.length === 0 && (
						<tr>
							<td colSpan="5">No participation data found.</td>
						</tr>
					)}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export default AssignmentReportsParticipation;
