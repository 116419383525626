import React from "react";
import classNames from "classnames";

export const Checkmark = (props) => {
  const classes = classNames("w-6 h-6", {
    "text-green-700": !props.red,
    "text-red-700": props.red,
  });

  return (
    <div className={classes}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
  );
};

export default Checkmark;
