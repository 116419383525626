import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";
import Joyride from "react-joyride";
import { useQuery, useQueryClient } from "react-query";
import { getMe, userQuerySettings } from "../../../queries/user";
import AssignmentNavigation from "../../../components/assignment-navigation/assignment-navigation";
import Editor from "../../../components/editor/editor";
import RubricForm from "../../../components/rubric-form/rubric-form";
import AssignmentSectionTitle from "../../../components/assignment-section-title/assignment-section-title";
import FilePreview from "../../../components/file-preview";

import { getFeedbackTodo } from "../../../queries/todos";
import {
	enableFeedbackJoyride,
	toggleFeedbackJoyride,
} from "../../../common/localstorage";
import styles from "./review-feedback-create.module.scss";

const getSteps = (user) => {
	if (user.data.is_demo) {
		return [
			{
				content: (
					<h2>
						Welcome! On this page you evaluate reviews or respond to questions
						assigned by your instructor.
					</h2>
				),
				placement: "center",
				target: "body",
			},
			{
				content: (
					<h2>
						This is where you find the submission associated with the reviews
						you will evaluate.
					</h2>
				),
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 0,
				target: ".assignment-navigation",
			},
			{
				content: (
					<p>
						Please ensure that you SAVE your response before moving to the next
						one!
					</p>
				),
				placement: "center",
				target: "body",
			},
		];
	} else {
		return [
			{
				content: (
					<h2>
						Welcome! On this page you evaluate reviews or respond to questions
						assigned by your instructor.
					</h2>
				),
				placement: "center",
				target: "body",
			},
			{
				content: (
					<h2>
						This is where you find the submission associated with the reviews
						you will evaluate.
					</h2>
				),
				floaterProps: {
					disableAnimation: true,
				},
				spotlightPadding: 0,
				target: ".assignment-navigation",
			},
			{
				content: (
					<p>
						Please ensure that you SAVE your response before moving to the next
						one! <br />
						<br />
						You cannot save work past the deadline.
					</p>
				),
				placement: "center",
				target: "body",
			},
		];
	}
};
function ReviewCreate(props) {
	const { id } = useParams();

	const {
		createOrUpdateReviewFeedback,
		resetReviewFeedbackCreated,
		reviewFeedbackCreated,
	} = props;

	const [previewOpen, setPreviewOpen] = useState(false);
	const [formState, setFormState] = useState();
	const queryClient = useQueryClient();
	const enableJoyride = enableFeedbackJoyride();
	const user = useQuery("me", getMe, userQuerySettings);

	const detail = getFeedbackTodo(id);
	useEffect(() => {
		setPreviewOpen("review");

		return () => {
			resetReviewFeedbackCreated();
		};
	}, [id]);

	const handleJoyRide = (data) => {
		const { action } = data;
		if (action === "reset" || action === "skip") {
			toggleFeedbackJoyride();
		}
	};

	const handleSubmitForm = () => {
		const formData = {
			review: detail.data.review.id,
			rubric: formState.rubricFormState,
		};
		createOrUpdateReviewFeedback(formData, () => {});
		queryClient.removeQueries(["todo-list"]);
	};

	useEffect(() => {
		if (reviewFeedbackCreated) {
			detail.refetch();
			if (!toast.isActive("review-feedback-create-save")) {
				toast("Saved", {
					containerId: "BOTTOM",
					className: "bg-blue-200 text-gray-900",
					toastId: "review-feedback-create-save",
				});
			}
			queryClient.removeQueries(["todo-list"]);
		}
	}, [reviewFeedbackCreated]);

	if (user.isLoading || detail.isLoading) {
		return <div>Loading</div>;
	}

	const steps = getSteps(user);

	const renderPreview = () => {
		if (previewOpen === "assignment") {
			return (
				<div className={styles.previewWrapper}>
					<AssignmentSectionTitle title="The Assignment" backgroundGray />
					<div
						className={classNames(styles.scrollwrapper, styles.backgroundGray)}
					>
						<div className={styles.content}>
							<Editor
								body={detail.data.assignment.body}
								readOnly
								toolbarHidden
							/>
						</div>
					</div>
				</div>
			);
		}

		if (previewOpen === "submission") {
			return (
				<div className={styles.previewWrapper}>
					<AssignmentSectionTitle title="The Submission" backgroundGray />
					<div className={styles.fileUploadContainer}>
						<div className={styles.filesPreviewContainer}>
							{detail.data.submission.files.map((file, index) => (
								<FilePreview file={file} readOnly key={file.file} />
							))}
						</div>
					</div>
					<div
						className={classNames(styles.scrollwrapper, styles.backgroundGray)}
					>
						<div className={styles.content}>
							<Editor
								body={detail.data.submission.body}
								readOnly
								toolbarHidden
							/>
						</div>
					</div>
				</div>
			);
		}

		if (previewOpen === "review") {
			return (
				<div className={styles.previewWrapper}>
					<AssignmentSectionTitle title="The Review" backgroundGray />
					<div className={styles.scrollwrapper}>
						<div className={styles.content}>
							<div className={classNames(styles.formWrapper, styles.splitView)}>
								<RubricForm
									readOnly
									submitForm={handleSubmitForm}
									data={detail.data.review.rubric}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	let feedbackHeader = "Score Reviews";
	if (detail.isSuccess && detail.data.review_feedback.is_complete) {
		feedbackHeader = "Your Completed Score Review";
	}

	return (
		<React.Fragment>
			<div
				className={classNames(styles.container, {
					"split-view": previewOpen,
				})}
			>
				<AssignmentNavigation
					title={detail.data.assignment.title}
					setPreviewOpen={setPreviewOpen}
					previewOpen={previewOpen}
					isDemo={user.data.is_demo}
					showAssignmentButton
					showSubmissionButton
					showReviewButton
				/>
				<div className={styles.sectionWrapper}>
					{renderPreview()}
					<div
						className={classNames(
							styles.previewWrapper,
							styles.previewWrapperMain,
						)}
					>
						<AssignmentSectionTitle
							title={feedbackHeader}
							showActions
							handleSave={handleSubmitForm}
							backgroundGreen={detail.data.review_feedback.is_complete}
						/>
						<div className={classNames(styles.scrollwrapper)}>
							<div className={styles.content}>
								<div
									className={classNames(styles.formWrapper, {
										[styles.splitView]: previewOpen,
									})}
								>
									<h2 className="font-bold mb-4">You are now in Stage 3</h2>
									<RubricForm
										data={detail.data.review_feedback.rubric}
										submitForm={handleSubmitForm}
										setFormState={setFormState}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Joyride
				run={enableJoyride}
				steps={steps}
				continuous
				scrollToFirstStep
				showProgress
				showSkipButton
				callback={handleJoyRide}
				styles={{
					options: {
						zIndex: 10000,
						arrowColor: "#fff",
						backgroundColor: "#fff",
						beaconSize: 36,
						overlayColor: "rgba(0, 0, 0, 0.5)",
						primaryColor: "#5850ec",
						spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
						textColor: "#333",
						width: undefined,
					},
				}}
			/>
		</React.Fragment>
	);
}

ReviewCreate.propTypes = {
	createOrUpdateReviewFeedback: PropTypes.func.isRequired,
	reviewFeedbackCreated: PropTypes.bool.isRequired,
	resetReviewFeedbackCreated: PropTypes.func.isRequired,
};

ReviewCreate.defaultProps = {};

export default ReviewCreate;
