import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Help from "../../../components/help/";
import StepSection from "../../../components/step-section/step-section";
import DateInput from "../../../components/date-input/date-input";
import FancyCheckbox from "../../../components/fancy-checkbox";
import Input from "../../../components/input/input";
import LeaveWarning from "../../../components/leave-warning/leave-warning";
import styles from "./assignment-edit.module.scss";

const CurrentTimezone = () => {
	// Show user what timezone is in effect for them
	const tz = moment.tz.guess();
	const currentTimezone = moment().tz(tz).format("zz");
	return (
		<span className="text-gray-600 text-sm">
			All times are in {tz} ({currentTimezone}) timezone
		</span>
	);
};

const LateWorkDueDate = (props) => {
	const {
		allowLateWork,
		late_work_due_date,
		errors,
		register,
		unregister,
		setValue,
		help,
	} = props;

	if (!allowLateWork) {
		return null;
	}

	return (
		<React.Fragment>
			<DateInput
				defaultDate={late_work_due_date}
				label="Late Work Due Date (Note: This is the final date by which students can complete a late submission in Stage 1 and two reviews of their classmates’ work in Stage 2.)"
				error={errors.late_work_due_date}
				register={register}
				unregister={unregister}
				setValue={setValue}
				name="late_work_due_date"
				help={help}
			/>
			<CurrentTimezone />
		</React.Fragment>
	);
};

const ReviewFeedbackDate = (props) => {
	const {
		skipFeedback,
		review_feedback_due_date,
		errors,
		register,
		unregister,
		setValue,
		help,
	} = props;

	if (skipFeedback) {
		return null;
	}

	return (
		<div className={styles.row}>
			<DateInput
				defaultDate={review_feedback_due_date}
				label="Score Review Due Date"
				error={errors.review_feedback_due_date}
				register={register}
				unregister={unregister}
				setValue={setValue}
				name="review_feedback_due_date"
				help={help}
			/>
			<CurrentTimezone />
		</div>
	);
};

const OralDueDate = (props) => {
	const {
		hasOral,
		oral_due_date,
		errors,
		register,
		unregister,
		setValue,
		help,
	} = props;

	if (!hasOral) {
		return null;
	}

	console.log("oralDueDate", oral_due_date);

	return (
		<div className={styles.row}>
			<DateInput
				defaultDate={oral_due_date}
				label="Timed Oral Assignment Due Date"
				error={errors.oral_due_date}
				register={register}
				unregister={unregister}
				setValue={setValue}
				name="oral_due_date"
				help={help}
			/>
			<CurrentTimezone />
		</div>
	);
};

const Step3 = (props) => {
	const {
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		register,
		unregister,
		watch,
		formState: { errors, isDirty },
	} = useForm();
	const [showDirtyMessage, setShowDirtyMessage] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showReleaseModal, setShowReleaseModal] = useState(false);
	const [AIEnabled, setAIEnabled] = useState(false);
	const [LateWorkEnabled, setLateWorkEnabled] = useState(false);
	const {
		setAssignmentDraft,
		assignmentDraft,
		goToNextStep,
		help,
		setCanClose,
	} = props;

	const watchSkipFeedback = watch("skip_feedback_state", false);
	const watchAI = watch("is_ai");
	const watchLateWork = watch("allow_late_work");
	const watchOral = watch("has_oral_assignment");

	useEffect(() => {
		setValue("league_size", assignmentDraft.league_size);
		setValue("publish_date", assignmentDraft.publish_date);
		setValue("due_date", assignmentDraft.due_date);
		setValue("review_due_date", assignmentDraft.review_due_date);
		setValue(
			"review_feedback_due_date",
			assignmentDraft.review_feedback_due_date,
		);
		setValue("automated", assignmentDraft.automated);
		setValue("sends_email", assignmentDraft.sends_email);
		setValue("show_students_results", assignmentDraft.show_students_results);
		setValue(
			"show_review_qualitative",
			assignmentDraft.show_review_qualitative,
		);
		setValue("skip_feedback_state", assignmentDraft.skip_feedback_state);

		// Late work
		setValue("allow_late_work", assignmentDraft.allow_late_work);
		setValue("late_work_due_date", assignmentDraft.late_work_due_date);
		setValue("late_work_ai", assignmentDraft.late_work_ai);
		setLateWorkEnabled(assignmentDraft.allow_late_work);

		// Oral
		setValue("has_oral_assignment", assignmentDraft.has_oral_assignment);
		setValue("oral_due_date", assignmentDraft.oral_due_date);

		// AI
		setValue("is_ai", assignmentDraft.is_ai);
		// Handle AI enabled/disabled UI behaviors
		setAIEnabled(assignmentDraft.is_ai);

		setValue(
			"include_ai_justification",
			assignmentDraft.include_ai_justification,
		);
		setValue(
			"include_ai_critical_thinking",
			assignmentDraft.include_ai_critical_thinking,
		);
		setValue("include_ai_writing", assignmentDraft.include_ai_writing);
	}, [assignmentDraft, setValue]);

	useEffect(() => {
		setAIEnabled(watchAI);
	}, [watchAI]);

	useEffect(() => {
		setLateWorkEnabled(watchLateWork);
	}, [watchLateWork]);

	useEffect(() => {
		if (isDirty) {
			setShowDirtyMessage(true);
			setCanClose(false);
		} else {
			setShowDirtyMessage(false);
			setCanClose(true);
		}
	}, [isDirty]);

	const onSubmit = (data) => {
		// Only move forward if we don't have errors
		setAssignmentDraft({
			...assignmentDraft,
			league_size: data.league_size,
			publish_date: data.publish_date,
			due_date: data.due_date,
			review_due_date: data.review_due_date,
			review_feedback_due_date: data.review_feedback_due_date,
			automated: data.automated,
			sends_email: data.sends_email,
			delay_stage_change_emails: data.delay_stage_change_emails,
			show_students_results: data.show_students_results,
			show_review_qualitative: data.show_review_qualitative,
			skip_feedback_state: data.skip_feedback_state,
			has_oral_assignment: data.has_oral_assignment,
			oral_due_date: data.oral_due_date,
			is_ai: data.is_ai,
			include_ai_justification: data.include_ai_justification,
			include_ai_critical_thinking: data.include_ai_critical_thinking,
			include_ai_writing: data.include_ai_writing,
			allow_late_work: data.allow_late_work,
			late_work_due_date: data.late_work_due_date,
			late_work_ai: data.late_work_ai,
		});

		goToNextStep();
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const closeReleaseModal = () => {
		setShowReleaseModal(false);
	};

	// In a team assignment it doesn't make sense to ask League Size
	let showLeagueSize = true;
	if (assignmentDraft.type == "team") {
		showLeagueSize = false;
	}

	let demoClasses = "";
	let demoInsert = null;

	return (
		<React.Fragment>
			<LeaveWarning when={showDirtyMessage} />
			<form
				className={styles.step}
				id="step-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				{demoInsert}
				<StepSection shadow className={demoClasses}>
					{showLeagueSize && (
						<div className={styles.row}>
							<Input
								label="League Size"
								name="league_size"
								defaultValue="4"
								type="number"
								error={errors.league_size}
								help={help}
								{...register("league_size", { required: true })}
								min={1}
							/>
						</div>
					)}
					<div className={styles.row}>
						<DateInput
							defaultDate={assignmentDraft.publish_date}
							label="Open For Submission"
							error={errors.publish_date}
							register={register}
							unregister={unregister}
							setValue={setValue}
							name="publish_date"
							help={help}
						/>
						<CurrentTimezone />
					</div>
					<div className={styles.row}>
						<DateInput
							defaultDate={assignmentDraft.due_date}
							label="Submission Due Date"
							error={errors.due_date}
							register={register}
							unregister={unregister}
							setValue={setValue}
							name="due_date"
							help={help}
						/>
						<CurrentTimezone />
					</div>
					<div className={styles.row}>
						<FancyCheckbox
							label="Allow Late Work (Note: Students who submit late do not receive peer feedback and cannot participate in Stage 3. For more information, click this"
							name="allow_late_work"
							register={register}
							unregister={unregister}
							help={help}
						/>
					</div>
					<div className={styles.row}>
						<LateWorkDueDate
							allowLateWork={watchLateWork}
							late_work_due_date={assignmentDraft.late_work_due_date}
							errors={errors}
							register={register}
							unregister={unregister}
							setValue={setValue}
							help={help}
						/>
					</div>
					<div className={styles.row}>
						<DateInput
							defaultDate={assignmentDraft.review_due_date}
							label="Review Due Date"
							error={errors.review_due_date}
							register={register}
							unregister={unregister}
							setValue={setValue}
							name="review_due_date"
							help={help}
						/>
						<CurrentTimezone />
					</div>
					<div className={styles.row}>
						<FancyCheckbox
							label="Skip Stage 3 - Score Review"
							name="skip_feedback_state"
							register={register}
							unregister={unregister}
							help={help}
						/>
					</div>
					<div className={styles.row}>
						<ReviewFeedbackDate
							skipFeedback={watchSkipFeedback}
							review_feedback_due_date={
								assignmentDraft.review_feedback_due_date
							}
							errors={errors}
							register={register}
							unregister={unregister}
							setValue={setValue}
							help={help}
						/>
					</div>
					{/*
          <div className={styles.row}>
            <FancyCheckbox
              label="Include Timed Oral Assignment"
              name="has_oral_assignment"
              register={register}
              unregister={unregister}
              help={help}
            />
          </div>
          <div className={styles.row}>
            <OralDueDate
              hasOral={watchOral}
              oral_due_date={assignmentDraft.oral_due_date}
              errors={errors}
              register={register}
              unregister={unregister}
              setValue={setValue}
              help={help}
            />
          </div>*/}
					<div className={styles.row}>
						<FancyCheckbox
							label="Automatically Move Stages"
							name="automated"
							register={register}
							unregister={unregister}
							help={help}
						/>
					</div>
					<div className={styles.row}>
						<FancyCheckbox
							label="Send Automated Emails"
							name="sends_email"
							register={register}
							unregister={unregister}
							help={help}
						/>
					</div>
					<div className="mb-4">
						<FancyCheckbox
							label="Allow ChatGPT to review student submissions and provide feedback in Stage 2"
							name="is_ai"
							register={register}
							unregister={unregister}
							help={help}
						/>
					</div>
					<div className="mb-4 pl-4">
						<FancyCheckbox
							label="Ask ChatGPT to justify its responses to 5-star rating questions, if applicable"
							name="include_ai_justification"
							register={register}
							unregister={unregister}
							help={help}
							disabled={!AIEnabled}
						/>
					</div>
					<div className="mb-4 pl-4">
						<FancyCheckbox
							label="Have ChatGPT provide feedback on students' demonstration of critical thinking"
							name="include_ai_critical_thinking"
							register={register}
							unregister={unregister}
							help={help}
							disabled={!AIEnabled}
						/>
					</div>
					<div className="mb-4 pl-4">
						<FancyCheckbox
							label="Have ChatGPT provide feedback on students' writing quality"
							name="include_ai_writing"
							register={register}
							unregister={unregister}
							help={help}
							disabled={!AIEnabled}
						/>
					</div>
					<div className="mb-4 pl-4">
						<FancyCheckbox
							label="Allow ChatGPT to provide feedback on student's submission if it is submitted late and not eligible for peer review. For more information on late submissions, click this"
							name="late_work_ai"
							register={register}
							unregister={unregister}
							help={help}
							disabled={!LateWorkEnabled}
						/>
					</div>
					<div className="mb-4 text-sm font-bold">
						<ul className="list-disc ml-4">
							<li className="ml-4">
								ChatGPT can analyze attachments; e.g. Word, PDF, Excel, CSV
								files, PPT, Google docs & slides. It cannot analyze images or
								videos within them.
							</li>
							<li className="ml-4">
								You will improve ChatGPT responses if you put your assignment in
								the assignment text box.{" "}
							</li>
							<li className="ml-4">ChatGPT options safeguard student data</li>
							<li className="ml-4">
								You can preview all ChatGPT responses before deciding to release
								them to students
							</li>
						</ul>
						<p className="my-2">
							For guidance on how to improve the quality of ChatGPT’s responses,
							click this
							<span
								onClick={() => setShowModal(!showModal)}
								className="text-gray-800"
							>
								<Help
									showModal={showModal}
									setShowModal={closeModal}
									title={help.data["chatgpt_options"].metadata.title}
									markdown={help.data["chatgpt_options"].markdown}
									buttonText={
										help.data["chatgpt_options"].metadata.help_url_text
									}
									buttonURL={help.data["chatgpt_options"].metadata.help_url}
								/>
							</span>
						</p>
						<p className="my-6">
							You will receive an email message asking if you are ready to
							release feedback. You can preview and select which feedback to
							release.
							<span
								onClick={() => setShowReleaseModal(!showModal)}
								className="text-gray-800"
							>
								<Help
									showModal={showReleaseModal}
									setShowModal={closeReleaseModal}
									title={help.data["release_feedback"].metadata.title}
									markdown={help.data["release_feedback"].markdown}
									buttonText={
										help.data["release_feedback"].metadata.help_url_text
									}
									buttonURL={help.data["release_feedback"].metadata.help_url}
								/>
							</span>
						</p>
					</div>
				</StepSection>
			</form>
		</React.Fragment>
	);
};

Step3.propTypes = {
	setAssignmentDraft: PropTypes.func.isRequired,
	goToNextStep: PropTypes.func.isRequired,
	assignmentDraft: PropTypes.shape({}).isRequired,
};

Step3.defaultProps = {};

export default Step3;
