import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ReactHtmlParser from "react-html-parser";

import Navigation from "../../../components/navigation/navigation";
import ScrollArea from "../../../components/scroll-area/scroll-area";
import TodoListItem from "../../../components/todo-list-item/todo-list-item";

import { getMe, userQuerySettings } from "../../../queries/user";
import { getTodos } from "../../../queries/todos";
import {
	getAssignment,
	getAssignmentMembers,
} from "../../../queries/student_assignments";
import styles from "./assignment-detail.module.scss";

const Members = (props) => {
	const { id } = useParams();
	const team_members = useQuery(["team-members"], () =>
		getAssignmentMembers(id),
	);

	if (team_members.isLoading) {
		return <div>Loading..</div>;
	}

	if (team_members.isError) {
		return (
			<div className="mt-4">
				Could not load team members for this assignment.
			</div>
		);
	}

	return (
		<div className="mt-8">
			<h2 className="text-lg font-bold">Your Team</h2>
			{team_members.data.map((member, index) => (
				<p className="pl-2 mt-2">{member.name}</p>
			))}
		</div>
	);
};

const Todos = (props) => {
	const { id } = useParams();
	const { assignment, showTodos } = props;
	const todos = useQuery(["todo-list"], getTodos, {
		cacheTime: 100,
		staleTime: 100,
		refetchOnMount: true,
	});

	if (todos.isLoading) {
		return <div>Loading..</div>;
	}

	if (todos.isError) {
		if (assignment.data.type == "team") {
			return (
				<div>
					Could not load TODO items for this assignment. This likely because you
					joined after this Team Assignment was published.
				</div>
			);
		}

		return <div>Could not load TODO items for this assignment.</div>;
	}

	// Only select the TODO items that match this assignment ID
	const visibleTodos = todos.data.filter(
		(t) => t.assignment_id === assignment.data.id,
	);
	return (
		<React.Fragment>
			{visibleTodos.length === 0 && (
				<div
					className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
					role="alert"
				>
					<span>
						You currently have no ToDo items that are in need of attention.
					</span>
				</div>
			)}
			{showTodos &&
				visibleTodos.map((item, index) => (
					<TodoListItem
						key={index}
						submissionID={item.submission_id}
						dueDate={item.due_date}
						type={item.todo_action}
						assignmentID={item.assignment_id}
						assignmentType={assignment.data.type}
						reviewID={item.review_id}
						reviewFeedbackID={item.review_feedback_id}
						oralID={item.oral_id}
						assignmentState={item.assignment_state}
						isComplete={item.is_complete}
						isActive={item.is_active}
						errorText={item.error_text || ""}
						submissionAuthor={item.author_name || ""}
						submissionModified={item.modified}
					/>
				))}
			{!showTodos && (
				<div
					className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
					role="alert"
				>
					<span>
						<strong>Class is currently paused</strong>
					</span>
					<br />
					{assignment.data.pause_message && (
						<p className="p-4">{assignment.data.pause_message}</p>
					)}
				</div>
			)}
		</React.Fragment>
	);
};

const AssignmentDetail = (props) => {
	const { id } = useParams();
	const user = useQuery("me", getMe, userQuerySettings);
	const assignment = useQuery(["student-assignment", id], () =>
		getAssignment(id),
	);
	const todos = useQuery(["todo-list"], getTodos, {
		cacheTime: 100,
		staleTime: 100,
		refetchOnMount: true,
	});

	if (user.isLoading || assignment.isLoading || todos.isLoading) {
		return <div>Loading...</div>;
	}

	// Whether or not the assignemnt is a team assignment
	let isTeamAssignment = false;
	if (assignment.data.type === "team") {
		isTeamAssignment = true;
	}
	// Allow hijacked users to see their TODOs but not
	let showTodos = true;
	if (assignment.data.paused && !user.data.is_hijacked_user) {
		showTodos = false;
	}

	let welcomeText = "Welcome";
	if (
		assignment.data.state !== "pending" &&
		assignment.data.state !== "submission"
	) {
		welcomeText = "Welcome Back";
	}

	let showLateNotice = false;
	const lateTODOs = todos.data.filter(
		(t) =>
			t.assignment_id === assignment.data.id &&
			t.todo_action.startsWith("late_"),
	);

	if (lateTODOs.length > 0 && assignment.data.allow_late_work) {
		showLateNotice = true;
	}

	// Ensure we don't show late notice in pending
	if (assignment.data.state === "pending") {
		showLateNotice = false;
	}

	return (
		<div className={styles.container}>
			<Navigation title={assignment.data.title} hasBackButton />
			<ScrollArea>
				<div className={styles.intro}>
					<div className={styles.welcome}>
						<div>
							<h1 className="text-2xl font-bold text-gray-600">
								{assignment.data.course_name}
							</h1>
							<h2 className="text-2xl font-bold my-8">
								{welcomeText}
								&nbsp;
								{user.data.first_name}!
							</h2>
							<p>
								Peer Exchange is designed to facilitate fair and constructive
								peer reviews. Here’s what you can expect during your Peer
								Exchange experience
							</p>
							{showLateNotice && (
								<div
									className="bg-blue-100 border border-blue-400 px-4 py-3 my-6"
									role="alert"
								>
									Your instructor approved a one-time extension for those who
									missed the Stage 1 deadline. It’s important to understand that
									this a unique allowance, exclusive to Stage 1. For students
									who made the first deadline, and for those of you who didn’t,
									there are no time-extensions available for subsequent stages.
								</div>
							)}
							{ReactHtmlParser(assignment.data.description)}
						</div>
					</div>
					<div className={styles.todos}>
						<div>
							<h1>Your ToDo's</h1>
							<Todos assignment={assignment} showTodos={showTodos} />
							{isTeamAssignment && <Members />}
						</div>
						<div></div>
					</div>
				</div>
			</ScrollArea>
		</div>
	);
};

export default AssignmentDetail;
