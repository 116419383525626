import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
import { useMutation, useQueryClient } from "react-query";
import { useUser } from "../../../queries/user";
import {
	useOralAssignment,
	uploadPracticeOral,
} from "../../../queries/student_assignments";
import Navigation from "../../../components/navigation/navigation";
import Loading from "../../../components/loading/loading";
import Countdown from "react-countdown";
import Webcam from "react-webcam";

const WebcamStreamCapture = ({
	assignmentID,
	userID,
	endTime,
	videoURL,
	setVideoURL,
}) => {
	const webcamRef = React.useRef(null);
	const mediaRecorderRef = React.useRef(null);
	const queryClient = useQueryClient();
	const [hasPermission, setHasPermission] = useState(false);
	const [capturing, setCapturing] = React.useState(false);
	const [uploading, setUploading] = React.useState(false);
	const [recordedChunks, setRecordedChunks] = React.useState([]);
	const [isDisabled, setDisabled] = useState(false);

	// Determine when we need to cut off the user
	const target = moment(endTime);
	const now = moment();
	const diffSeconds = target.diff(now);

	const audioConstraints = {
		echoCancellation: false,
		autoGainControl: false,
		noiseSuppression: false,
		audioBitsPerSecond: 256000,
		audioBitRateMode: "constant",
	};

	const upload = useMutation(uploadPracticeOral, {
		onSettled: () => {
			setUploading(false);
			queryClient.invalidateQueries(["student-oral-practice"]);
		},
		onSuccess: (data, variables, context) => {
			console.log("oral practice uploaded successfully");
			setVideoURL(data.video_url);
		},
		onError: (data, error, variables, context) => {
			console.log("Upload error");
			console.dir(data);
			console.dir(variables);
			rollbar.error(error, context);
		},
	});

	const handleStartCaptureClick = React.useCallback(() => {
		setCapturing(true);
		// Try different MIME types in order of preference
		const mimeTypes = [
			"video/webm;codecs=vp9,opus",
			"video/webm;codecs=vp8,opus",
			"video/webm;codecs=h264,opus",
			"video/webm",
			"video/mp4",
		];

		let selectedMimeType = null;
		for (const mimeType of mimeTypes) {
			if (MediaRecorder.isTypeSupported(mimeType)) {
				selectedMimeType = mimeType;
				break;
			}
		}

		if (!selectedMimeType) {
			console.error("No supported MIME types found");
			return;
		}

		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
			mimeType: selectedMimeType,
			videoBitsPerSecond: 2500000, // 2.5 Mbps
		});
		mediaRecorderRef.current.addEventListener(
			"dataavailable",
			handleDataAvailable,
		);
		mediaRecorderRef.current.start();
	}, [webcamRef, setCapturing, mediaRecorderRef]);

	const handleDataAvailable = React.useCallback(
		({ data }) => {
			console.log("In handle data");
			if (data.size > 0) {
				setUploading(true);
				setRecordedChunks((prev) => prev.concat(data));

				const blob = new Blob([data], {
					type: "video/webm",
				});

				upload.mutate({
					id: assignmentID,
					userID: userID,
					videoBlob: blob,
				});
			}
		},
		[upload, userID, assignmentID, recordedChunks],
	);

	const handleStopCaptureClick = React.useCallback(() => {
		mediaRecorderRef.current.stop();
		setCapturing(false);
	}, [recordedChunks]);

	navigator.permissions
		.query({ name: "camera" })
		.then((result) => {
			if (result.state === "granted") {
				setHasPermission(true);
			}

			if (result.state === "denied") {
				setPermissionDenied(true);
			}
		})
		.catch((error) => {
			// Likely firefox which doesn't support permissions API the same way
			navigator.mediaDevices
				.getUserMedia({ audio: true, video: true })
				.then((mediaStream) => {
					setHasPermission(true);
				})
				.catch((error) => {
					setHasPermission(false);
				});
		});
	console.log("hasPermission", hasPermission);

	// Set up timer to cut off user at the appropirate time
	useEffect(() => {
		const timeOutID = setTimeout(() => {
			console.log("Cutting off user!");
			handleStopCaptureClick();
		}, diffSeconds);

		return () => {
			if (timeOutID) clearTimeout(timeOutID);
		};
	}, [diffSeconds, handleStopCaptureClick]);

	const handleStartClick = useCallback(() => {
		if (isDisabled) return;

		setDisabled(true);
		handleStartCaptureClick();

		// Prevent any clicks for 5 seconds
		setTimeout(() => {
			setDisabled(false);
		}, 5000);
	}, [isDisabled, handleStartCaptureClick]);

	const handleStopClick = () => {
		if (isDisabled) return;
		handleStopCaptureClick();
	};

	const buttonClasses = classNames("px-4 py-3 w-64 rounded text-white", {
		"bg-red-500 hover:bg-red-700": capturing,
		"bg-green-500 hover:bg-green-700": !capturing,
		"cursor-not-allowed": isDisabled,
	});

	return (
		<>
			{!hasPermission && (
				<div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
					You must grant permission to use your camera and microphone in your
					browser to record your oral assignment. You should see a popup message
					asking for your permission.
				</div>
			)}
			{uploading && (
				<div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
					<p>
						Uploading! Do not close your browser tab or navigate away until this
						is complete. This may take a few minutes for larger videos,
						especially on slower internet connections.
					</p>
				</div>
			)}
			{!uploading && !videoURL && (
				<>
					<div className="w-full flex justify-center">
						<Webcam
							audio={true}
							muted={true}
							ref={webcamRef}
							mirrored={true}
							audioConstraints={audioConstraints}
						/>
					</div>
					<div className="mt-8 w-full flex justify-center">
						{capturing ? (
							<div>
								<button
									type="button"
									className={buttonClasses}
									disabled={isDisabled}
									onClick={handleStopClick}
								>
									Stop Recording
								</button>
								<Countdown
									date={endTime}
									renderer={({ seconds }) => {
										return (
											<span className="ml-8 text-gray-500 text-sm">
												Time Remaining: {seconds} seconds
											</span>
										);
									}}
								/>
							</div>
						) : (
							<div>
								<button
									type="button"
									className={buttonClasses}
									onClick={handleStartClick}
								>
									Click Here to Start Recording
								</button>
								<Countdown
									date={endTime}
									renderer={({ seconds }) => {
										return (
											<span className="ml-8 text-gray-500 text-sm">
												Time Remaining: {seconds} seconds
											</span>
										);
									}}
								/>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export const OralPractice = () => {
	const { id } = useParams();
	const [videoURL, setVideoURL] = useState(null);
	const history = useHistory();
	const user = useUser();
	const assignment = useOralAssignment(id);

	if (assignment.isLoading || user.isLoading) {
		return <Loading />;
	}

	const startTime = moment();
	const endTime = startTime + 0.5 * 60 * 1000;

	return (
		<div>
			<Navigation
				history={history}
				title={assignment.data.title}
				backURL={`/my-oral-assignments/${id}`}
				hasBackButton
			/>
			<div className="p-4">
				<div className="my-2">
					<h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
						Oral Assignment Practice
					</h2>
				</div>

				{!videoURL && (
					<div className="w-full mt-8 p-4 bg-blue-100 border border-blue-300 text-center mb-8">
						{startTime.isValid() && (
							<Countdown
								date={endTime}
								renderer={({ seconds }) => {
									return (
										<span className="font-bold text-xl">
											Time Remaining: {seconds} seconds
										</span>
									);
								}}
							/>
						)}
					</div>
				)}

				<div className="w-full mt-8 p-6 bg-gray-100 border border-gray-400 mb-8">
					<h2 className="text-2xl font-semibold">Your Question:</h2>
					<p className="mt-6">
						<em>
							The question your instructor has assigned you will appear here.
						</em>
					</p>
				</div>

				<WebcamStreamCapture
					assignmentID={id}
					userID={user.data.id}
					oralID={assignment.data.id}
					endTime={endTime}
					videoURL={videoURL}
					setVideoURL={setVideoURL}
				/>

				{videoURL && (
					<>
						<h3 className="text-xl font-semibold my-6 text-center">
							Here is the video you recorded to verify your audio and video is
							working properly
						</h3>
						<div className="w-full flex items-center justify-center">
							<video src={videoURL} width="800" height="600" controls />
						</div>
					</>
				)}
				<div className="mt-24 mb-24 w-full flex justify-center">
					<a
						className="px-4 py-3 rounded text-white bg-blue-500 hover:bg-blue-700"
						href={`/my-oral-assignments/${id}`}
					>
						Return To Assignment
					</a>
				</div>
			</div>
		</div>
	);
};

export default OralPractice;
