import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getMe, userQuerySettings } from "../../../queries/user";
import {
	getAssignment,
	getAssignmentDefaults,
	getStudentResult,
} from "../../../queries/admin-queries";
import classNames from "classnames";
import Loading from "../../../components/loading/loading";
import ScrollArea from "../../../components/scroll-area/scroll-area";
import styles from "../../student/results/results.module.scss";

const buttonClasses = classNames(
	"rounded py-2 px-4 ml-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer",
);

const NotAvailable = (props) => {
	return (
		<div className="rounded bg-blue-100 p-4 w-full my-4">
			<p className="py-2">
				You may view your submission from the link to the right.
			</p>
		</div>
	);
};

const DisplayReview = (props) => {
	const { review, index } = props;
	const items = [];
	review.qualitative.map((item) => {
		items.push(
			<p className="font-bold my-1" key={`label-${item.id}`}>
				{item.label}
			</p>,
		);
		items.push(
			<p className="my-1" key={`answer-${item.id}`}>
				{item.answer}
			</p>,
		);
	});

	let css = "my-4 p-1 border border-1 border-grey-400 bg-gray-100 p-4";
	let title = `#${index + 1} Review`;

	if (review.is_ai) {
		css = "my-4 p-1 border border-1 p-4 border-red-400 bg-red-200";
		title = `#${index + 1} AI Review`;
	}

	try {
		return (
			<div className={css}>
				<h4 className="text-lg font-bold">{title}</h4>
				{items}
				{!items.length && (
					<span>
						Your assignment had no textual review questions to display
					</span>
				)}
			</div>
		);
	} catch (e) {
		console.dir(e);
	}
};

const DisplayReviewResults = (props) => {
	const { reviews } = props;

	return (
		<div className="my-6">
			<h4 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
				Reviews of your Stage 1 Submission
			</h4>
			{reviews.map((object, i) => (
				<DisplayReview review={object} index={i} key={`drr-${i}`} />
			))}
		</div>
	);
};

const StudentResults = (props) => {
	const { id, sectionMemberID } = useParams();
	const history = useHistory();
	const user = useQuery("me", getMe, userQuerySettings);
	const assignment = useQuery(
		["admin-assignment", id],
		() => getAssignment(id),
		getAssignmentDefaults,
	);
	const results = useQuery(["admin-student-results", id, sectionMemberID], () =>
		getStudentResult(id, sectionMemberID),
	);

	if (user.isLoading || assignment.isLoading || results.isLoading) {
		return <Loading />;
	}

	if (
		assignment.data.state == "pending" &&
		assignment.data.state == "submission"
	) {
		return (
			<React.Fragment>
				<h1 className="text-2xl font-bold mb-4">Results</h1>
				<p className="p-4 mt-8 border bg-blue-100">
					This report is only available in Stage 2 and later.
				</p>
			</React.Fragment>
		);
	}

	let reviewTitleText = "Review";
	if (results.data.reviews.length > 1) {
		reviewTitleText = "Reviews";
	}

	// Show the results
	return (
		<div className={styles.container}>
			<ScrollArea>
				<div className={styles.content}>
					<div className={styles.welcome}>
						<h3 className="p-2">
							{results.data.submission.author_name} Results
						</h3>
						<Link
							to={`/submissions/${results.data.submission.id}`}
							className={buttonClasses}
						>
							View Their Submission
						</Link>
						{assignment.data.show_review_comparison && (
							<Link
								to={`/assignments/${id}/reports/results/${sectionMemberID}/compare`}
								className={buttonClasses}
							>
								Compare Their Stage 2 Reviews to Others'
							</Link>
						)}
						{assignment.data.show_students_results && (
							<Link
								to={`/assignments/${id}/reports/results/${sectionMemberID}/feedback`}
								className={buttonClasses}
							>
								View classmates' comments on their Reviews
							</Link>
						)}
					</div>
					{!assignment.data.show_students_results && <NotAvailable />}
					{assignment.data.show_students_results && (
						<DisplayReviewResults reviews={results.data.reviews} />
					)}

					{assignment.data.release_ai_critical_thinking_feedback &&
						results.data.submission.ai_critical_thinking_feedback && (
							<div className="my-6">
								<h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
									ChatGPT Critical Thinking Feedback
								</h4>
								<div
									className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4"
									style={{ whiteSpace: "pre-line" }}
								>
									{results.data.submission.ai_critical_thinking_feedback}
								</div>
							</div>
						)}
					{assignment.data.release_ai_writing_feedback &&
						results.data.submission.ai_writing_feedback && (
							<div className="my-6">
								<h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
									ChatGPT Writing Feedback
								</h4>
								<div
									className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4"
									style={{ whiteSpace: "pre-line" }}
								>
									{results.data.submission.ai_writing_feedback}
								</div>
							</div>
						)}
				</div>
			</ScrollArea>
		</div>
	);
};

export default StudentResults;
