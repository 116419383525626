import React, { useState, useEffect } from "react";
import moment from "moment";
import { shortDate } from "../../../common/utils";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Joyride from "react-joyride";
import { useQuery, useQueryClient } from "react-query";
import { getMe, userQuerySettings } from "../../../queries/user";
import { getTodos } from "../../../queries/todos";
import {
	getAssignment,
	getAssignmentSubmission as queryGetAssignmentSubmission,
} from "../../../queries/student_assignments";
import AssignmentSectionTitle from "../../../components/assignment-section-title/assignment-section-title";
import AssignmentNavigation from "../../../components/assignment-navigation/assignment-navigation";
import Button from "../../../components/button/button";
import Editor from "../../../components/editor/editor";
import SubmissionFileUpload from "../../../components/submission-file-upload";
import FilePreview from "../../../components/file-preview";
import styles from "./submission-create.module.scss";
import Loading from "../../../components/loading/loading";
import Modal from "../../../components/modal/modal";

import {
	enableSubmissionJoyride,
	toggleSubmissionJoyride,
} from "../../../common/localstorage";

// Joyride steps if normal
let steps = [
	{
		content: (
			<div>
				<h2>
					Welcome! This page is for your submission. It's{" "}
					<strong>very important</strong> to meet all deadlines.
				</h2>
			</div>
		),
		placement: "center",
		target: "body",
	},
	{
		content: <h2>You can access related documents for this assignment.</h2>,
		floaterProps: {
			disableAnimation: true,
		},
		spotlightPadding: 0,
		target: ".assignment-navigation",
	},
	{
		content: (
			<div>
				<p className="my-2">
					You MUST click SAVE above or below. <br />
					<br />A green banner will appear at the top of the page, and the
					"To-Do" you're working on will turn green. If you see one of these,
					your work has been saved.
					<br />
					<br />
					There is not a Submit button.
					<br />
					You can edit your work at any time.
					<br />
					At the deadline the last version you saved will be your submission.
				</p>
			</div>
		),
		placement: "center",
		target: "body",
	},
];

// Joyride steps if late
let lateSteps = [
	{
		content: (
			<div>
				<h2>Welcome! This page is for your late submission.</h2>
			</div>
		),
		placement: "center",
		target: "body",
	},
	{
		content: <h2>You can access related documents for this assignment.</h2>,
		floaterProps: {
			disableAnimation: true,
		},
		spotlightPadding: 0,
		target: ".assignment-navigation",
	},
	{
		content: (
			<div>
				<p className="my-2">
					You MUST click SAVE either above or below. <br />
					<br />A green banner will appear at the top of the page, and the
					"To-Do" you're working on will turn green. If you see one of these,
					your work has been saved.
					<br />
					<br />
					You can edit your work at any time.
					<br />
					When ready, return to the "ToDo's" page and select "Submit Your Late
					Submission".
				</p>
			</div>
		),
		placement: "center",
		target: "body",
	},
];

const DemoModal = (props) => {
	const { customCloseHandler } = props;
	const buttonClasses = classNames(
		"rounded py-2 px-4 text-lg text-white w-full bg-green-500",
	);

	return (
		<Modal
			title="Student Commitment Question"
			customCloseHandler={customCloseHandler}
		>
			<p className="my-4">
				Peer learning is most effective when all students participate. However,
				we know that well-intentioned students often face obstacles completing
				assignments. This question helps us to gauge participation and an
				equitable distribution of work. Students can skip this question, or as
				we explain to them, can change their response.
			</p>
			<button onClick={() => customCloseHandler()} className={buttonClasses}>
				Continue Demo
			</button>
		</Modal>
	);
};

const CommitmentBox = (props) => {
	const {
		committed,
		setCommitted,
		hasStage3,
		isTeamAssignment,
		isComplete,
		authorName,
		modified,
		isLate,
	} = props;

	// Don't show this box if it's in a late work lane
	if (isLate) {
		return (
			<div className="bg-blue-200 p-4">
				Submitting late work requires you to select two To-Do's: First SAVE then
				Submit. Please read these instructions carefully. Save your work as
				often as you need by clicking “Save” at the top or bottom of the page.{" "}
				<br />
				<br />
				When ready: Return to the “To-Do's” page by clicking the 'X' in the
				upper right corner. 2. Now select “Submit Your Late Submission.” This
				submits your work and allows you to review classmates' work in Stage
				2/Review.
			</div>
		);
	}

	// Don't show this on team assignments at all
	if (isTeamAssignment) {
		if (isComplete) {
			const modifiedDate = moment(modified).toDate();
			return (
				<div className="bg-green-200 p-4">
					{authorName} submitted this at {shortDate(modified)}
				</div>
			);
		} else {
			return null;
		}
	}

	// Customize text for if the assignment has a stage 3
	let finalText = "Stages 2 and 3";
	if (!hasStage3) {
		finalText = "Stage 2";
	}

	if (committed === true) {
		// User has committed to continuing
		return (
			<div className={styles.committed}>
				<span className="my-4">I'm likely to participate in {finalText}</span>
				<button
					className="text-gray-600 hover:text-gray-800 hover:underline font-light"
					onClick={() => setCommitted(null)}
				>
					Change answer
				</button>
			</div>
		);
	} else if (committed === false) {
		// User has indicated they are NOT continuing
		return (
			<div className={styles.uncommitted}>
				<span className="my-4">
					I indicated that I was unlikely to participate, so will not advance to{" "}
					{finalText}
				</span>
				<button
					className="text-gray-800 hover:text-gray-900 hover:underline font-light"
					onClick={() => setCommitted(null)}
				>
					Change answer
				</button>
			</div>
		);
	} else {
		// User hasn't made a choice yet
		return (
			<div className={styles.commitmentWrapper} id="commitment-label">
				<p className="my-4">
					Please confirm that you are likely to participate in {finalText}
				</p>
				<div className="flex my-4">
					<Button
						className="bg-green-600 hover:bg-green-800"
						onClick={() => setCommitted(true)}
					>
						Yes, I'm likely to participate
					</Button>
					<button
						type="button"
						className="ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						onClick={() => setCommitted(false)}
					>
						No
					</button>
				</div>
			</div>
		);
	}
};

function SubmissionCreate(props) {
	const { id } = useParams();

	const { createOrUpdateSubmission, submissionCreated, deleteSubmissionFile } =
		props;
	const [previewOpen, setPreviewOpen] = useState("assignment");
	//const enableJoyride = useSelector((state) => state.ui.joyride_submission);
	const enableJoyride = enableSubmissionJoyride();
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [showDemoModal, setShowDemoModal] = useState(null);
	const [committed, setCommitted] = useState(null);

	const toggleDemo = (value) => {
		setShowDemoModal(value);
		toggleSubmissionJoyride();
	};

	const [editorDraftState, setEditorDraftState] = useState({
		body: "",
		plain_text: "",
		loaded: false,
	});

	const queryClient = useQueryClient();
	const user = useQuery("me", getMe, userQuerySettings);
	const assignment = useQuery(["student-assignment", id], () =>
		getAssignment(id),
	);
	const sub = useQuery(["assignment-submission", id], () =>
		queryGetAssignmentSubmission(id),
	);

	// Handle completeness tracking
	const todos = useQuery(["todo-list"], getTodos, {
		cacheTime: 100,
		staleTime: 100,
		refetchOnMount: true,
	});

	let completed = false;
	if (sub.isSuccess && sub.data.is_complete) {
		completed = true;
	}

	useEffect(() => {
		if (submissionCreated) {
			setSelectedFiles([]);
			sub.refetch();
		}
	}, [submissionCreated]);

	useEffect(() => {
		if (user.data.is_demo) {
			toggleSubmissionJoyride();
		}
	}, [user]);

	useEffect(() => {
		if (selectedFiles.length > 0) {
			createOrUpdateSubmission({
				title: "",
				assignment: id,
				body: editorDraftState.body,
				body_plaintext: editorDraftState.plain_text || "",
				files: selectedFiles,
				committed: committed, // Student commitment
			});
			sub.refetch();
			queryClient.removeQueries(["todo-list"]);
		}
	}, [selectedFiles]);

	if (sub.isLoading || assignment.isLoading || user.isLoading) {
		return <Loading />;
	}

	if (sub.isSuccess && editorDraftState.loaded === false) {
		setEditorDraftState({
			body: sub.data.body,
			plain_text: sub.data.body_plaintext,
			loaded: true,
		});
		setCommitted(sub.data.committed);
	}

	const handleJoyRide = (data) => {
		const { action } = data;
		console.log("Action", action);
		if (action === "reset" || action === "skip") {
			toggleSubmissionJoyride();
		}
	};

	const handleSubmitForm = () => {
		createOrUpdateSubmission({
			title: "",
			assignment: id,
			body: editorDraftState.body,
			body_plaintext: editorDraftState.plain_text || "",
			files: selectedFiles,
			committed: committed, // Student commitment
		});
		queryClient.removeQueries(["todo-list"]);
	};

	const renderPreview = () => {
		if (previewOpen === "assignment") {
			return (
				<div className={styles.previewWrapper}>
					<AssignmentSectionTitle title="The Assignment" backgroundGray />
					<div
						className={classNames(styles.scrollwrapper, styles.backgroundGray)}
					>
						<div className={styles.content}>
							<Editor body={assignment.data.body} readOnly toolbarHidden />
						</div>
					</div>
				</div>
			);
		}
		return null;
	};

	const renderSubmissionFiles = () => {
		const handleClick = (e, id) => {
			deleteSubmissionFile(id);
		};

		if (sub.data.files) {
			return (
				<div className={styles.filesPreviewContainer}>
					{sub.data.files.map((file, index) => (
						<FilePreview
							file={file}
							handleClick={handleClick}
							key={file.file}
						/>
					))}
				</div>
			);
		}
		return null;
	};

	let submissionHeader = "Your Submission";
	if (completed) {
		submissionHeader = "Your Completed Submission";
	}

	if (sub.data.is_late) {
		submissionHeader = "Late Submission Guide";
	}

	let isLate = false;

	if (assignment.data.allow_late_work) {
		if (
			moment(assignment.data.due_date) <= moment() ||
			assignment.data.state !== "submission"
		) {
			isLate = true;
		}
	}

	let shownSteps = steps;
	if (assignment.data.allow_late_work && isLate) {
		shownSteps = lateSteps;
	}

	console.log("isLate", isLate);
	console.log(shownSteps);
	console.log(enableJoyride);

	return (
		<React.Fragment>
			<div
				className={classNames(styles.container, {
					"split-view": previewOpen,
				})}
			>
				<AssignmentNavigation
					title={assignment.data.title}
					setPreviewOpen={setPreviewOpen}
					previewOpen={previewOpen}
					isDemo={user.data.is_demo}
					showAssignment
				/>
				{showDemoModal && <DemoModal customCloseHandler={toggleDemo} />}
				<div className={styles.sectionWrapper}>
					{renderPreview()}

					<div className={styles.previewWrapper}>
						<AssignmentSectionTitle
							title={submissionHeader}
							handleSave={handleSubmitForm}
							backgroundGreen={completed}
							showActions
						/>
						{!user.data.is_demo && (
							<CommitmentBox
								committed={committed}
								setCommitted={setCommitted}
								hasStage3={!assignment.data.skip_feedback_state}
								isTeamAssignment={assignment.data.type == "team"}
								authorName={sub.data.author_name}
								modified={sub.data.modified}
								isComplete={sub.data.is_complete}
								isLate={isLate}
							/>
						)}
						<div className={styles.fileUploadContainer}>
							<div className={styles.fileUploadButton}>
								<SubmissionFileUpload
									selectedFiles={selectedFiles}
									setSelectedFiles={setSelectedFiles}
								/>
							</div>
							{renderSubmissionFiles()}
						</div>
						<div className={classNames(styles.scrollwrapper)}>
							<div className={styles.content}>
								<Editor
									body={sub.data.body}
									handleBodyUpdate={setEditorDraftState}
								/>
							</div>
							<button
								className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-4 rounded"
								onClick={handleSubmitForm}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			<Joyride
				run={enableJoyride}
				steps={shownSteps}
				continuous
				scrollToFirstStep
				showProgress
				showSkipButton
				callback={handleJoyRide}
				styles={{
					options: {
						zIndex: 10000,
						arrowColor: "#fff",
						backgroundColor: "#fff",
						beaconSize: 36,
						overlayColor: "rgba(0, 0, 0, 0.5)",
						primaryColor: "#5850ec",
						spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
						textColor: "#333",
						width: undefined,
					},
				}}
			/>
		</React.Fragment>
	);
}

SubmissionCreate.propTypes = {
	createOrUpdateSubmission: PropTypes.func.isRequired,
	submissionCreated: PropTypes.bool.isRequired,
	submission: PropTypes.shape({
		body: PropTypes.string.isRequired,
		body_plaintext: PropTypes.string,
	}).isRequired,
};

SubmissionCreate.defaultProps = {};

export default SubmissionCreate;
