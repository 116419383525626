import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Avatar from "../../../components/avatar/avatar";
import Checkmark from "../../../components/checkmark/checkmark";
import Refresh from "../../../components/refresh";
import Filter from "../../../components/filter";
import Pagination from "../../../components/pagination";

import X from "../../../components/x/x";
import styles from "./assignment-detail.module.scss";

export const FilterLink = (props) => {
	const { text, type, filter, setFilter, setCurrentPage, setQueryFilter } =
		props;
	const titleText = "Filter " + text;
	if (filter === type) {
		return (
			<a
				className="mx-2 text-gray-600 hover:text-gray-900"
				onClick={() => {
					setFilter(false);
					setCurrentPage(1);
					setQueryFilter(false);
				}}
			>
				Show All
			</a>
		);
	} else {
		return (
			<a
				title={titleText}
				className="mx-2 text-gray-600 hover:text-gray-900"
				onClick={() => setFilter(type)}
			>
				<Filter /> {text}
			</a>
		);
	}
};

const handleRefreshData = (queryClient, refreshKeys) => {
	refreshKeys.forEach((key) => {
		queryClient.invalidateQueries(key);
	});
};

export const FilterTable = (props) => {
	const {
		items,
		linkPrefix,
		assignmentID,
		refreshKeys,
		currentPage,
		nextPageNum,
		previousPageNum,
		totalPages,
		setCurrentPage,
		setQueryFilter,
		isTeamAssignment,
		hasLateColumn,
	} = props;
	const [filter, setFilter] = useState(false);
	const queryClient = useQueryClient();
	let displayItems = items;
	if (filter === "completes") {
		setQueryFilter("completes");
	}
	if (filter === "incompletes") {
		setQueryFilter("incompletes");
	}

	// Move back to page one when filters change
	useEffect(() => {
		setCurrentPage(1);
	}, [filter]);

	// build our filter download link
	const downloadURL = `/api/export/csv/${linkPrefix}/${assignmentID}/${filter}/`;

	try {
		return (
			<React.Fragment>
				<div className="float-left my-4">
					<Pagination
						nextPageNum={nextPageNum}
						previousPageNum={previousPageNum}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						totalPages={totalPages}
					/>
				</div>
				<div className="float-right my-4">
					{filter !== false && (
						<a
							className="mx-2h text-gray-600 hover:text-black"
							href={downloadURL}
						>
							Download {filter}
						</a>
					)}
					<FilterLink
						text="Completes"
						type="completes"
						filter={filter}
						setFilter={setFilter}
						setCurrentPage={setCurrentPage}
						setQueryFilter={setQueryFilter}
					/>
					<FilterLink
						text="Incompletes"
						type="incompletes"
						filter={filter}
						setFilter={setFilter}
						setCurrentPage={setCurrentPage}
						setQueryFilter={setQueryFilter}
					/>
					<a
						className="mx-2 text-gray-600 hover:text-gray-900"
						onClick={() => handleRefreshData(queryClient, refreshKeys)}
					>
						<Refresh /> Refresh All Data
					</a>
				</div>
				<table>
					<thead>
						<tr>
							<th>By</th>
							<th>Complete</th>
							{hasLateColumn && <th>Submitted Late</th>}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{displayItems.map((item, index) => (
							<tr key={index}>
								<td>
									<div className={styles.author}>
										<div className={styles.authorAvatar}>
											<Avatar name={item.author.display_name} />
										</div>
										<div className={styles.authorInfo}>
											<div className={styles.authorName}>
												{item.author.display_name}
											</div>
											<div className={styles.authorEmail}>
												{item.author.email}
											</div>
											{isTeamAssignment && (
												<div className={styles.authorEmail}>
													{item.team_name}
												</div>
											)}
										</div>
									</div>
								</td>
								<td>
									{item.is_complete ? (
										<span className="text-green-500">COMPLETE</span>
									) : (
										<span className="text-red-500">INCOMPLETE</span>
									)}
								</td>
								{hasLateColumn && (
									<td>
										{item.is_late ? (
											<span className="text-red-500">LATE</span>
										) : null}
									</td>
								)}
								<td>
									<Link to={`/${linkPrefix}/${item.id}`}>View</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="float-left my-4 py-6">
					<Pagination
						nextPageNum={nextPageNum}
						previousPageNum={previousPageNum}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						totalPages={totalPages}
					/>
				</div>
			</React.Fragment>
		);
	} catch (e) {
		console.dir(e);
	}
};
